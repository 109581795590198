import React, { useEffect, useState } from 'react';
import iconFilePdf from "../../../assets/media/icon-file-pdf.svg";
import iconFileXls from "../../../assets/media/icon-file-xls.svg";
import iconFileImage from "../../../assets/media/icon-file-image.svg";
import iconFileDoc from "../../../assets/media/icon-file-doc.svg";
import iconFileDownload from "../../../assets/media/icon-file-download.svg";
import iconSearch from "../../../assets/media/icon-search.svg";
import iconCaretLeft from "../../../assets/media/icon-caret-left.svg";
import iconCaretRight from "../../../assets/media/icon-caret-right.svg";
import iconCaretDown from "../../../assets/media/icon-caret-down.svg";
import iconMenuDots from '../../../assets/media/icon-menu-dots.svg';

import { Link } from 'react-router-dom';
import Navbar from '../../navbar';
import Header from '../../header';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../dashboard/aapDashboard';
import { fetchApplications, updateApplication } from '../../../store/reducers/applicationSlice';
import Pagination from '../dashboard/appPagination';
import {handleFilesDownload} from '../../../store/reducers/fileSlice';

const RejectedApp = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_token;

    const applications = useSelector((state) => state.applications);

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const getallUserdata = useSelector((state) => state.userdata.getalluserdata);
    const [filteredApplication, setFilteredApplication] = useState([]);

    const handleChangeStatus = async (item, status) => {
        console.log('item, status', item, status)
        await dispatch(updateApplication({
            id: item?.id,
            state: status,

            accessToken: authToken
        })).then(e => {
            dispatch(fetchApplications({ accessToken: authToken }))
        })
    }

    useEffect(() => {
        dispatch(fetchApplications({ accessToken: authToken }));
    }, [authToken, dispatch]);


    useEffect(() => {
        if (applications.data.length > 0 && getallUserdata.length > 0) {
            const mergedData = applications.data.map((appItem) => {
                const userItem = getallUserdata.find((userData) => userData.id === appItem.user_id);
                if (userItem) {
                    return {
                        ...appItem,
                        applicationId: appItem.id, 
                        ...userItem,
                        userId: userItem.id, 
                    };
                }
                return { ...appItem, applicationId: appItem.id }; 
            });
    
            const filteredData = mergedData.filter((item) =>
            (item.company_id === user.user.company_id || user.user.is_admin)&&
            item.state === "rejected" &&
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    
            setFilteredApplication(filteredData);
            console.log(filteredData, 'Filtered Data');
        }
    }, [searchQuery, applications.data, getallUserdata, user.user.company_id]);

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const getTotalPages = () => {
        if (Number.isInteger(filteredApplication.length / 10)) return filteredApplication.length / 10;
        return Math.floor(filteredApplication.length / 10) + 1;
    };
    const handleDownload = (item) => {
        handleFilesDownload({item,authToken});
    };


    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>
                <div className='boxAppBoard boxWhite'>
                    <div className='boardHeader'>
                        <div className='boardHeadLeft'>
                            <h2>In Process Applications</h2>
                        </div>
                        <div className='boardHeadRight'>
                            <div className='searchForm'>
                                <input type='text' placeholder='input search text' onChange={handleSearchInputChange} value={searchQuery} />
                                <button><img src={iconSearch} alt='' /></button>
                            </div>
                            <div className='datePicker'>
                            </div>
                        </div>
                    </div>
                    <div className='boardContent'>
                        <div className='boardThead'>
                            <div className='boardCol w-85'>
                                S.NO.
                            </div>
                            <div className='boardCol w-165'>
                                Application No.
                            </div>
                            <div className='boardCol w-adj2'>
                                Application Name
                            </div>
                            <div className='boardCol w-165'>
                                Submitted Date
                            </div>
                            <div className='boardCol w-165'>
                            </div>
                            <div className='boardCol w-355'>
                                Reason
                            </div>
                            <div className='boardCol w-110'>
                            </div>
                        </div>
                        {filteredApplication.sort((a, b) => b.applicationId - a.applicationId).map((item, index) => (
                            <div className={`boardTrow ${(currentPage === 1 ? currentPage : ((currentPage - 1) * 10) + 1) <= index + 1 && index < (currentPage === 1 ? 10 : (currentPage * 10)) ? '' : 'd-none'} `} key={item.id}>
                                <div className='boardCol w-85'>
                                    {index + 1}
                                </div>
                                <div className='boardCol w-165'>
                                {`GB-000000${item.applicationId}`}
                                </div>
                                <div className='boardCol w-adj2'>
                                    <div className='boardTitle'>
                                        <strong>{item.name}</strong>
                                        <span>{item.form}</span>
                                    </div>
                                </div>
                                <div className='boardCol w-165'>
                                {item?.updated_at ? item.updated_at.slice(0, 10) : ''}
                                </div>
                                <div className='boardCol w-165'>
                                    <div className='boardDownload'>
                                   
                                    </div>
                                </div>
                                <div className='boardCol w-355'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...
                                </div>
                                <div className='boardCol w-110'>
                                    <div className='boardActions'>
                                        <div className="dropdown d-inline-flex">
                                            <button className="application-dropdown dropdown-toggle border-0 bg-transparent" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={iconMenuDots} alt="Menu Dots" />
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <li>
                                                    <Link  onClick={() => handleDownload(item.applicationId)} className="dropdown-item">
                                                        Download
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => {
                                                        handleChangeStatus(item, 2) // 2 is pending
                                                    }} className="dropdown-item">
                                                        Pending
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => {
                                                        handleChangeStatus(item, 3) // 3 is pending
                                                    }} className="dropdown-item">
                                                        Pending From Dw
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => {
                                                        handleChangeStatus(item, 4) // 4 is approved
                                                    }} className="dropdown-item">
                                                        Approved
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => {
                                                        handleChangeStatus(item, 7) // 7 is renewal
                                                    }} className="dropdown-item">
                                                        Renewal
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => {
                                                        handleChangeStatus(item, 8) // 8 is drafts
                                                    }} className="dropdown-item">
                                                        Drafts
                                                    </Link>
                                                </li>
                                            
                                                
                                                <li><hr class="dropdown-divider" /></li>
                                                <li><a className="dropdown-item" href="#">Remove</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={getTotalPages}
                    />
                </div>
            </div>
        </div>
    )
}

export default RejectedApp;
