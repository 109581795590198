import React, { useEffect, useState } from 'react';
import {setFormFieldData,loadForm} from '../../../utility/utility';

const DownloadApp = () =>{
    const searchParams = new URLSearchParams(window.location.search);
    const [applicationName,setApplicationName] = useState(searchParams.get('name'));
    const [applicationData,setApplicationData] = useState(searchParams.get('data')); 
    useEffect(() => {
        // Function to be called after 1000 milliseconds
        const delayedFunction = () => {
            setFormFieldData( JSON.parse(applicationData));
            window.print();
        };
    
        // Set the timeout
        const timer = setTimeout(delayedFunction, 2000);
    
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
      }, []);
    return (
        <div id="form-container">
            {loadForm({ type: applicationName })}
        </div>
    );
};

export default DownloadApp;