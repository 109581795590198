import React ,{useState,useEffect}from 'react';
import Navbar from '../../navbar';
import Header from '../../header';
import FormHeader from '../../forms/formHeader/formHeader'
import { useDispatch, useSelector } from 'react-redux';
import { createFormMandatoryField } from '../../../store/reducers/formMandatoryFieldsSlice'; // Adjust path as needed
import {getFormPageIds,hideAllPagesExceptCurrentFormPage,setInputFieldAndFormPageIds,loadForm} from '../../../utility/utility';
import FormLoader from '../../forms/formLoader';
const AppFormCreation = () => {

    const [formIds, setFormIds] = useState([]);
    const [mandatoryFields,setMandatoryFields] = useState([])
    const [pageIds,setPageIds] = useState([]);
    const [currentPage,setCurrentPage] = useState(1);
    const user = useSelector((state) => state.auth.user);
    const {category,applicationName,formId} = useSelector((state)=>state.counter.createFormData);
    const dispatch = useDispatch();
    const [isLoaded,setIsLoaded] =useState(false);
    
    
      // Use useEffect to dynamically add event listeners
      useEffect(() => {
        attachEventHandlers();
        console.log(category);
        // Cleanup event listeners on unmount
        return () => {
          formIds.forEach((id) => {
            const inputField = document.getElementById(id);
            if (inputField) {
              inputField.removeEventListener('click', handleInputChange);
            }
          });
        };
      }, [formIds,isLoaded]);

      useEffect(()=>{
        // console.log(pageIds);
      hideAllPagesExceptCurrentFormPage(pageIds,currentPage);

      },[pageIds])

      const handleInputChange = (event) => {
        if(event.target.style.backgroundColor==="rgb(255, 0, 0)"){
            setMandatoryFields((prevFields) => prevFields.filter((id) => id !== event.target.id));
            event.target.style.backgroundColor = '';
        }else{
            event.target.style.backgroundColor = '#ff0000'; // Change background color to red
            setMandatoryFields((prevFields) => [...prevFields, event.target.id]);
        }
      };
    
      // Function to attach event handlers
      const attachEventHandlers = () => {
        formIds.forEach((id) => {
          const inputField = document.getElementById(id);
          if (inputField) {
            // console.log("Event Listner Attached ",id);
            inputField.addEventListener('click', handleInputChange);
          }
        });
      };

      const handleCreate = () => {
        const newField = {
          user_id: 1,
          form_id: formId,
          company_id: 1,
          mandatory_fields:mandatoryFields,
        };
        const authToken = user.access_token;
        dispatch(createFormMandatoryField({ data: newField, authToken }));
      };


    return (
        <div>
            <Navbar />
            <Header />
            <div className='wrapper'>

                <div className='profHeader'>
                    <div className='profHeadLeft'>
                        <h2>{category}</h2>
                        <span>Select mandatory fields*</span>
                    </div>
                    
                    <div >
                      <a href='#' className='buttonPrimary' onClick={handleCreate}>Save</a>
                    </div>
                </div>
                <div className='sp20'></div>
             <FormHeader applicationName={applicationName} currentPage={currentPage} totalPages={pageIds.length} pageIds={pageIds} setCurrentPage={setCurrentPage}/>
          <FormLoader applicationName={applicationName} setFormIds={setFormIds} setPageIds={setPageIds} setIsLoaded={setIsLoaded}/>

</div>

        </div>
    )
}
export default AppFormCreation