
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {  updateApplication } from '../../../../store/reducers/applicationSlice';
import { createNotification } from '../../../../store/reducers/notificationSlice';

const Index = ({selectedFieldData,setSelectedFieldData,formId}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const authToken = user.access_to
    const accessToken = user.access_token;

    const handleAddDiscrepancy = async() => {
        const currentTime = new Date().toLocaleTimeString();
        await dispatch(
            updateApplication({
                accessToken,
                id: formId,
                currentTime,
                descripencie:selectedFieldData,
            })
        );
        dispatch(createNotification({
            message: `Your Application Descrepencies`,
            user_id: user.user.id,
            accessToken: authToken,
            is_admin: false
        }))
    };
    const handleInputChange = (id,value) =>{
        const idForm = id.replace("_text", "")
        setSelectedFieldData((prevFields) => {
            const newFields = { ...prevFields };
   
            newFields[idForm].value = value;
            return newFields;
        });
    }
    const handleTextChange = (event) =>{
        console.log(event);
        setSelectedFieldData((prevData) => ({
            ...prevData,        // Spread the previous state to keep existing keys
            ["other"]: event.target.value,       // Add or update the key-value pair
        }));
    }
    const handleCheckboxChange = (id) =>{
        const idCheck = id.replace("_check", "")
        setSelectedFieldData((prevFields) => {
            const newFields = { ...prevFields };
            delete newFields[idCheck]
            return newFields;
        });
        const idForm = idCheck.replace("dis_","");
        const remove = document.getElementById(idForm);
        remove.style.backgroundColor = '';
    }

    return (
        <div className="discontainer">
            <div className='title'>Add Discrepancies</div>
            <div className='disform'>
                <div className='boardHeadRight'>
                    <div className='boardFilter'>
                    {Object.entries(selectedFieldData).map(([id, data]) => (
    <div className="form-check form-check-inline" key={id}>
        <input
            id= {id+"_check"}
            className="form-check-input"
            type="checkbox"
            checked={data.check} // Reflect the checked state
            onChange={() => handleCheckboxChange(id)} // Handle checkbox changes
        />
        <input
            id={id + "_text"}
            type="text"
            placeholder="add discrepancy"
            value={data.value} // Reflect the value state
            onChange={(event) => handleInputChange(id, event.target.value)} // Handle text input changes
            className="form-check-label"
        />
    </div>
))}
                    </div>
                </div>
                <label className="mainlabel mt">Other</label>
                <textarea onChange={(event)=>handleTextChange(event)}/>
            </div>
            <div className="buttonGroup">
                <a className="AppFormButton" onClick={handleAddDiscrepancy}>Update Discrepancies</a>
            </div>
        </div>
    );
};
export default Index;
